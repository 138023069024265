import React, { useEffect } from 'react';
import { useRef } from 'react';

export type UseEditingMethods = {
    setEditingKey: (
        editableKey: string,
        onConfirmChangeCallback?: (newEditingKey: string) => Promise<void>
    ) => void;
    clearEditingKey: () => void;
    editingKey: EditableKey;
};
export type EditableKey = string;

export type EditingProviderProps = {
    children: React.ReactNode;
} & UseEditingMethods;

const EditingContext = React.createContext<UseEditingMethods | null>(null);

EditingContext.displayName = 'EditingContext';

export const useEditingContext = (): UseEditingMethods =>
    React.useContext(EditingContext) as UseEditingMethods;

export const EditingProvider = ({
    children,
    ...props
}: EditingProviderProps) => (
    <EditingContext.Provider value={{ ...props }}>
        {children}
    </EditingContext.Provider>
);

export const useEditingContextWithCleanup = (editableKey: EditableKey) => {
    const { editingKey, setEditingKey, clearEditingKey } = useEditingContext();

    const setEditingKeyRef = useRef(setEditingKey);
    const clearEditingKeyRef = useRef(clearEditingKey);

    useEffect(() => {
        setEditingKeyRef.current = setEditingKey;
        clearEditingKeyRef.current = clearEditingKey;
    }, [clearEditingKey, setEditingKey]);

    useEffect(() => {
        if (setEditingKeyRef.current) setEditingKeyRef.current(editableKey);

        return () => {
            if (clearEditingKeyRef.current) clearEditingKeyRef.current();
        };
    }, [editableKey]);

    return { editingKey, setEditingKey, clearEditingKey };
};
