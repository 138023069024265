import React, { Fragment } from 'react';

import { Grid, Typography } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { OTHER_INCOME_MAP } from 'constants/other-income';
import { useI18n } from 'providers/i18n/use-i18n';
import { getApplicant, getApplicantIdSelected } from 'store/applications';
import { SectionDetailsProps } from 'types/submission-notes';
import { formatMoney } from 'utils';

import { Comments } from './comments';
import { EmptySection } from './empty-section';
import styles from './section-details.module.scss';

export const Income = ({
    sectionCompleted,
    title,
    submissionNotes,
}: SectionDetailsProps) => {
    const { i18n } = useI18n();
    const { applicationId } = submissionNotes;

    const { employments: employmentsFields, others: othersFields } =
        submissionNotes?.customFields?.incomes || {};

    const applicantId = useRecoilValue(getApplicantIdSelected(applicationId));

    const { income } = useRecoilValue(
        getApplicant({ applicationId, applicantId })
    );

    const { employments, others } = income;

    if (employments.length === 0 && others.length === 0) {
        return <EmptySection applicationId={applicationId} title={title} />;
    }

    return (
        <>
            {employments.length > 0 && (
                <>
                    <Typography>{i18n._('employments')}</Typography>
                    <Grid className={styles.grid}>
                        {employments.map((employment) => {
                            const { amount, frequency } =
                                employment?.salary?.base || {};

                            return (
                                <Fragment key={employment.id}>
                                    <EditableCell
                                        name="income"
                                        fieldType="readonly"
                                        label="salary.base.amount"
                                        value={`${formatMoney(
                                            amount
                                        )} / ${frequency.toLowerCase()}`}
                                    />
                                    <EditableCell
                                        name="incomeSource"
                                        fieldType="readonly"
                                        label="incomeSource"
                                        value={employment.jobTitle}
                                    />
                                    <Comments
                                        isEditing={!sectionCompleted}
                                        name={`incomes.employments[${employment.id}].comments`}
                                        label="description"
                                        value={
                                            employmentsFields[
                                                `${employment.id}`
                                            ]?.comments
                                        }
                                    />
                                </Fragment>
                            );
                        })}
                    </Grid>
                </>
            )}
            {others.length > 0 && (
                <>
                    <Typography>{i18n._('otherIncome')}</Typography>
                    <Grid className={styles.grid}>
                        {others.map((other) => {
                            const { amount, frequency } = other?.income || {};

                            return (
                                <Fragment key={other.id}>
                                    <EditableCell
                                        name="income"
                                        fieldType="readonly"
                                        label="salary.base.amount"
                                        value={`${formatMoney(
                                            amount
                                        )} / ${frequency.toLowerCase()}`}
                                    />
                                    <EditableCell
                                        name="incomeSource"
                                        fieldType="readonly"
                                        label="incomeSource"
                                        value={i18n._(
                                            OTHER_INCOME_MAP[other.type]
                                                ?.labelKey || ''
                                        )}
                                    />
                                    <Comments
                                        isEditing={!sectionCompleted}
                                        name={`incomes.others[${other.id}].comments`}
                                        label="description"
                                        value={
                                            othersFields[`${other.id}`]
                                                ?.comments
                                        }
                                    />
                                </Fragment>
                            );
                        })}
                    </Grid>
                </>
            )}
        </>
    );
};
